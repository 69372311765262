import request from './config'
import * as qiniu from 'qiniu-js'
import {formatTime} from '../assets/js/utils'

import store from '@/store'

// 获取二维码
export function getQrCode() {
  return request.get('/put/wechat/ad/qrcode')
}

// 获取二维码状态

export function getQrCodeStatus(params) {
  return request.get(`/put/wechat/ad/qrcode/status/${params}`)
}

// 微信广告渠道刷新

export function refreshChannelAD(params) {
  return request.get(`/put/wechat/ad/channel/refresh/${params}`)
}

// 获取广告服务生登录信息

export function getAdInfo(params) {
  return request.get('/put/wechat/ad', {params})
}

// 获取推广页 列表

export function getWeChatPage(params) {
  return request.get('/put/wechat/page', {params})
}

// 获取单个推广页详情

export function getWeChatPageDetail(params) {
  return request.get(`/put/wechat/page/${params}`)
}

// 新增推广页

export function addWeChatPage(params) {
  return request.post('/put/wechat/page', params)
}

// 更新推广页

export function updateWeChatPage(id, params) {
  return request.put(`/put/wechat/page/${id}`, params)
}

// 删除推广页
export function deleteWeChatPage(params) {
  return request.delete(`/put/wechat/page/${params}`)
}

// 投放计划列表

export function getWeChatPlan(params) {
  return request.get('/put/wechat/plan', {params})
}

// 获取投放计划详情

export function getPerWeChatPlanDetail(id) {
  return request.get(`/put/wechat/plan/${id}`)
}

// *新增投放计划

export function addWeChatPlan(params) {
  return request.post('/put/wechat/plan', params)
}

// 修改投放计划

export function editWeChatPlan(id, params) {
  return request.put(`/put/wechat/plan/${id}`, params)
}

// 删除投放计划

export function deleteWeChatPlan(id) {
  return request.delete(`/put/wechat/plan/${id}`)
}

// 获取微信地域信息/手机品牌/表情

export function getWeChatRegion(params) {
  return request.get('/put/wechat/region', {params})
}

// 推送至公众号

export function pushAppIdCampaign(params) {
  return request.post('/put/wechat/plan/pushAppIdCampaign', params)
}

// 获取公众人号人群信息

export function getWechatRegionCrowd(params) {
  return request.get('/put/wechat/region/getCrowd', {params})
}

// 获取计划推送的公众号列表

export function getPushAppIdList(params) {
  return request.get('/put/wechat/plan/pushAppIdList', {params})
}

// 获取用户拥有的公众号列表

export function getUserChannelList() {
  return request.get('/put/wechat/plan/getUserAppId')
}

// 获取今日广告列表

export function getAdBoardList(params) {
  return request.get('/statistics/adInfo', {params})
}

// 地域格式预检查

export function checkRegionList(params) {
  return request.post('/put/wechat/regionHistory/condition', params)
}

// 新增地域历史记录

export function addRegionHistory(params) {
  return request.post('/put/wechat/regionHistory', params)
}

// 获取地域历史记录

export function getRegionHistory(params) {
  return request.get('/put/wechat/regionHistory', {params})
}

// 删除地域历史记录

export function delRegionHistory(ids) {
  return request.delete(`/put/wechat/regionHistory/${ids}`)
}

// 素材库列表

export function getWeChatSourceList(params) {
  return request.get('/put/wechat/source', {params})
}

// 素材库新增

export function addWeChatSource(params) {
  return request.post('/put/wechat/source', params)
}

// 素材库更新

export function editWeChatSource(id, params) {
  return request.put(`/put/wechat/source/${id}`, params)
}

// 素材库删除
export function delWeChatSource(id) {
  return request.delete(`/put/wechat/source/${id}`)
}

// 获取小程序跳转列表
export function getMiniprogramList(params) {
  return request.get('/miniprogram/jump/miniprogram', {
    params
  })
}

// 获取小程序跳转列表
export function getMiniprogramInvalid() {
  return request.get('/miniprogram/jump/miniprogram/invalidAppid')
}

// 获取小程序跳转列表
export function getMiniprogramSetting() {
  return request.get('/miniprogram/jump/miniprogram/setting')
}

// 小程序跳转新增
export function updateMiniProgram(params) {
  return request.post('/miniprogram/jump/miniprogram', params)
}

// 小程序跳转修改

export function editMiniProgram(id, params) {
  return request.put(`/miniprogram/jump/miniprogram/${id}`, params)
}

// 小程序跳转批量删除

export function deleteMiniProgram(id) {
  return request.delete(`/miniprogram/jump/miniprogram/${id}`)
}

// 图片压缩上传

export function compressUpload({file, classify = 'novel_put'}) {
  const type = file.type.split('/')[1]
  const Time = formatTime(Date.now(), 'yyyyMMdd')
  const key = Time + (Math.random() * (99999 - 10000 + 1) + 10000).toFixed()
  return new Promise((resolve, reject) => {
    request.get('/admin/token').then(res => {
      qiniu.upload(file, `${classify}/${key}.${type}`, res, {
          fname: '',
          params: {},
          mimeType: ['image/png', 'image/jpeg', 'video/mp4', 'video/avi', 'video/mov'] || null
        },
        {useCdnDomain: true, region: qiniu.region.z2})
        .subscribe({
          error(err) {
            reject(err.message)
          },
          next({total}) {
            store.commit('setUploadPercent', total.percent)
          },
          complete(res) {
            resolve(`https://file.gaohaiwl.com/${res.key}`)
          }
        })
    }).catch(err => {
      reject(err.message)
    })
  })
}


// 投放派单相关====================================================

// 查询投手派单
export function getPutDelivery(params) {
  return request.get(`/put/delivery`, {params})
}

// 新增派单
export function addPutDelivery(params) {
  return request.post(`/put/delivery`, params)
}

// 修改派单
export function ChangePutDelivery(id, params) {
  return request.put(`/put/delivery/${id}`, params)
}

// 删除派单
export function DeletePutDelivery(id) {
  return request.delete(`/put/delivery/${id}`)
}

// 接单查询
export function getPutDeliveryArt(params) {
  return request.get(`/put/delivery/art`, {params})
}

// 接单新增
export function addPutDeliveryArt(params) {
  return request.post(`/put/delivery/art`, params)
}

// 接单修改
export function changePutDeliveryArt(id, params) {
  return request.put(`/put/delivery/art/${id}`, params)
}

// 删除接单
export function deletePutDeliveryArt(id) {
  return request.delete(`/put/delivery/art/${id}`)
}

// 查询关联计划
export function getputDeliveryArtCampaign(params) {
  return request.get(`/put/delivery/art/campaign`, {params})
}

// 新增关联计划
export function addputDeliveryArtCampaign(params) {
  return request.post(`/put/delivery/art/campaign`, params)
}

// 修改关联计划
export function changeputDeliveryArtCampaign(id, params) {
  return request.put(`/put/delivery/art/campaign/${id}`, params)
}

// 删除关联计划
export function deleteputDeliveryArtCampaign(id) {
  return request.delete(`/put/delivery/art/campaign/${id}`)
}

// 投放派单相关结束====================================================
// 查询美工统计

export function getArtDelivery(params) {
  return request.get('/put/delivery/artStats', {params})
}

// 派单计划列表
export function getDeliveryCampaign(id, params) {
  return request.get(`/put/delivery/campaign/${id}`, {params})
}

// 获取落地页列表
export function getLandPageList(params) {
  return request.get('/put/landPage', {params})
}

// 落地页新增

export function addLandPage(params) {
  return request.post('/put/landPage', params)
}

// 删除落地页(可批量)

export function delLandPage(id) {
  return request.delete(`/put/landPage/${id}`)
}

// 落地页更新

export function updateLandPage(id, params) {
  return request.put(`/put/landPage/${id}`, params)
}

// 落地页素材图片链接获取
export function getRemoteLink(params) {
  return request.get('/put/landPage/remoteLink', {params})
}


// 落地页设置相关==============

//查询落地页设置列表
export function getQuickApp(params) {
  return request.get('/upload/quickApp', {params})
}

//新增落地页设置
export function addQuickApp(params) {
  return request.post('/upload/quickApp', params)
}

//修改落地页设置
export function updateQuickApp(id, params) {
  return request.put(`/upload/quickApp/${id}`, params)
}

//删除落地页设置
export function deleteQuickApp(ids) {
  return request.delete(`/upload/quickApp/${ids}`)
}

// 查询回传数据
export function getQuickAppCallBack(params) {
  return request.get(`/upload/quickApp/callBack`, {params})
}

// 批量上传
export function uploadQuickAppCallBack(ids) {
  return request.put(`/upload/quickApp/callBack/${ids}`,)
}
