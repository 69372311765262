<template>
  <el-container class='height-calc-type1'>
    <aside-category
      :cateType="5"
      @asideSelect="handleSelect"
      @asideToEdit="toCategoryEdit"
      ref="_asideCategory"
    ></aside-category>
    <el-container>
        <el-main class="main-cont  border-radius-6">
            <el-table
              :data='dataList'
              height="calc(100vh - 312px)"
            >

            </el-table>
        </el-main>
    </el-container>
    <el-drawer
      :title="`${editForm.id ? '编辑' : '新增'}分类`"
      :visible.sync="isCategoryEdit"
      size="450px"
    >
      <el-form label-width="60px">
        <el-form-item label="名称">
          <el-input v-model="editForm.name" placeholder="分类名称" />
        </el-form-item>
        <el-form-item label="类别">
          <el-select
            v-model="editForm.type"
            style="width: 100%"
            :clearable="false"
            disabled
          >
            <el-option label="客服消息" :value="1"></el-option>
            <el-option label="巨量引擎" :value="2"></el-option>
            <el-option label="书籍管理" :value="3"></el-option>
            <el-option label="板块管理" :value="4"></el-option>
            <el-option label="素材管理" :value='5'></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" style='text-align:center'>
        <el-button @click="isCategoryEdit = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitCategoryEdit"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </el-container>
</template>
<script>
import AsideCategory from '../category'
import { getWeChatSourceList } from '@/api/novelPut.js'
import {
  editClassify,
  getClassifyList,
  changeClassify
} from '@/api/classify.js'
export default {
  name: 'resourceManageMent',
  data () {
    return {
      search: '',
      id: null,
      type: 5,
      pageSize: 10,
      page: 1,
      categoryList: [],
      dataList: [],
      editForm: {
        id: null,
        name: '',
        type: 5
      },
      total: 0,
      showDrawer: false,
      isCategoryEdit: false,
      status: null,
      loading: false
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      this.loading = true
      getClassifyList({
        page: this.page,
        pageSize: this.pageSize,
        type: this.type,
        status: this.status
      })
        .then(res => {
          this.total = res.length
          this.categoryList = res
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSelect () {},
    toCategoryEdit (data, type = 5) {
      if (data) {
        this.editForm = {
          type: data.type,
          name: data.name,
          id: data.id
        }
      } else {
        this.editForm = {
          type,
          name: ''
        }
      }
      this.isCategoryEdit = true
    },
    handleStatusChange (item) {
      const status = item.status
      changeClassify(item.id, status).then(() => {
        this.$message({
          showClose: true,
          message: `${status === 0 ? '已关闭' : '已启用'}`,
          type: 'success'
        })
      })
    },
    async handleSubmitCategoryEdit () {
      await editClassify({ ...this.editForm })
      this.isCategoryEdit = false
      this.resetData()
      this.initData()
    },
    handleClose () {},
    resetData () {
      this.editForm = {
        name: '',
        type: this.type
      }
    },
    handlePageChange (val) {
      this.page = val
      this.initData()
    }
  },
  components: {
    AsideCategory
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-drawer {
  box-sizing: border-box;
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.el-main .main-cont {
  margin-left:16px;
  background-color: #fff;
}
.resource-management {
  height: 80vh;
  .resource-category-container {
    .search-input {
    }
    .category-list {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      .category-item {
        box-sizing: border-box;
        margin-right: 10px;
        border: 1px solid #e0e6ed;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        > p {
          margin: 10px 0;
          > span:nth-of-type(1) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
